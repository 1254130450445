/* begin general import */
import {
  Add16,
  OverflowMenuHorizontal24,
  SettingsAdjust16,
  TrashCan16,
} from "@carbon/icons-react";
import { Dropdown, Menu, PaginationProps, Spin } from "antd";
import { ColumnProps } from "antd/lib/table";
import PageHeader from "components/PageHeader/PageHeader";
import { SUBJECT_ROUTE } from "config/route-consts";
import { Semester, SemesterFilter } from "models/Semester";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionBarComponent,
  Button,
  OneLineText,
  Pagination,
  StandardTable,
  TagFilter,
} from "react3l-ui-library";
import { detailService } from "services/page-services/detail-service";
import { filterService } from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { masterService } from "services/page-services/master-service";
import { queryStringService } from "services/page-services/query-string-service";
import {
  getAntOrderType,
  tableService,
} from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import SemesterDetailDrawer from "../SemesterDetail/SemesterDetailDrawer";
import SemesterAdvanceFilter from "./SemesterAdvanceFilter";
import { semesterRepository } from "repositories/semester-repository";
import { renderMasterIndex } from "helpers/table";
import InputSearch from "components/InputSearch";
import "./SemesterMaster.scss";
import { Moment } from "moment";
import { formatDate } from "helpers/date-time";
import LayoutHeader from "components/LayoutHeader";
import LayoutCell from "components/LayoutCell";
import { formatNumber } from "helpers/number";
import TruncateCell from "components/TruncateCell";
import AdvanceDateRangeFilterMaster from "components/AdvanceDateRangeFilter";
/* end individual import */

function SemesterMaster() {
  const [translate] = useTranslation();
  const [modelFilter, dispatch] = queryStringService.useQueryString(
    SemesterFilter,
    { skip: 0, take: 10 }
  );

  const {
    value: filter,
    handleChangeAllFilter,
    handleChangeDateMasterFilter,
    handleChangeInputSearch,
  } = filterService.useFilter(modelFilter, dispatch);

  const { list, count, loadingList, handleResetList, handleLoadList } =
    listService.useList(
      semesterRepository.list,
      semesterRepository.count,
      filter,
      dispatch
    );

  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeAllFilter
  );

  const {
    handleAction,
    handleBulkAction,
    canBulkAction,
    selectedRowKeys,
    rowSelection,
    setSelectedRowKeys,
  } = listService.useRowSelection(
    semesterRepository.delete,
    semesterRepository.bulkDelete,
    null,
    null,
    null,
    handleResetList
  );

  const { handleDeleteItem } = masterService.useMasterAction(
    SUBJECT_ROUTE,
    handleAction
  );

  const {
    model,
    dispatch: dispatchModal,
    isOpenDetailModal,
    handleOpenDetailModal,
    handleCloseDetailModal,
    handleSaveModel,
    loadingModel,
    handleChangeSingleField,
    handleChangeDateField,
  } = detailService.useDetailModal(
    Semester,
    semesterRepository.get,
    semesterRepository.save,
    handleLoadList
  );

  const [visible, setVisible] = React.useState<boolean>(false);

  const menuAction = React.useCallback(
    (id: number, semester: Semester) => (
      <Menu>
        {
          <Menu.Item key="1">
            <div
              className="ant-action-menu text-center"
              onClick={() => handleOpenDetailModal(id)}
            >
              {translate("general.actions.edit")}
            </div>
          </Menu.Item>
        }

        {!semester?.used && (
          <Menu.Item key="2">
            <div
              className="ant-action-menu text-center"
              onClick={handleDeleteItem(semester)}
            >
              {translate("general.actions.delete")}
            </div>
          </Menu.Item>
        )}
      </Menu>
    ),
    [translate, handleDeleteItem, handleOpenDetailModal]
  );

  const pagination: PaginationProps = tableService.usePagination(filter, count);

  const columns: ColumnProps<Semester>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
            position="center"
          />
        ),
        key: "index",
        width: 60,
        render(...params: [number, Semester, number]) {
          const index = renderMasterIndex<Semester>(pagination)(...params);
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={formatNumber(index)} />
            </LayoutCell>
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "code"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("semesters.code")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].code),
        dataIndex: nameof(list[0].code),
        sorter: true,
        sortOrder: getAntOrderType<Semester, SemesterFilter>(
          filter,
          nameof(list[0].code)
        ),
        width: "30%",
        render(...params: [string, Semester, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "name"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("semesters.name")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].name),
        dataIndex: nameof(list[0].name),
        sorter: true,
        sortOrder: getAntOrderType<Semester, SemesterFilter>(
          filter,
          nameof(list[0].name)
        ),
        render(...params: [string, Semester, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "startedAt"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("semesters.startedAt")}
              sortedColumn={sortedColumn}
              isSorter
              position="center"
            />
          );
        },
        key: nameof(list[0].startedAt),
        dataIndex: nameof(list[0].startedAt),
        sorter: true,
        sortOrder: getAntOrderType<Semester, SemesterFilter>(
          filter,
          nameof(list[0].startedAt)
        ),
        width: 200,
        render(...params: [Moment, Semester, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={params[0] ? formatDate(params[0]) : ""} />
            </LayoutCell>
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "endedAt"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("semesters.endedAt")}
              sortedColumn={sortedColumn}
              isSorter
              position="center"
            />
          );
        },
        key: nameof(list[0].endedAt),
        dataIndex: nameof(list[0].endedAt),
        sorter: true,
        sortOrder: getAntOrderType<Semester, SemesterFilter>(
          filter,
          nameof(list[0].endedAt)
        ),
        width: 200,
        render(...params: [Moment, Semester, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={params[0] ? formatDate(params[0]) : ""} />
            </LayoutCell>
          );
        },
      },
      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("general.actions.label")}
            />
          );
        },
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 80,
        align: "center",
        render(id: number, semester: Semester) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id, semester)}
                trigger={["click"]}
                placement="bottom"
                arrow
              >
                <OverflowMenuHorizontal24 />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [translate, pagination, list, filter, menuAction]
  );

  const handleCancel = React.useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <>
      <Spin spinning={loadingList}>
        <div className="page-content semester-master">
          <PageHeader
            title={translate("semesters.master.subHeader")}
            breadcrumbItems={[
              translate("semesters.master.header"),
              translate("semesters.master.subHeader"),
            ]}
          />

          <div className="page page-master m-l--sm m-r--xxl m-b--xxs">
            <div className="page-master__title p-l--sm p-t--xs p-b--xs">
              {translate("semesters.master.subHeader")}
            </div>
            <div className="page-master__content">
              <div className="page-master__tag-filter">
                <TagFilter
                  value={filter}
                  translate={translate}
                  keyTranslate={"semesters"}
                  handleChangeFilter={handleChangeAllFilter}
                  onClear={() => {
                    return 0;
                  }}
                />
              </div>
              {(!selectedRowKeys || selectedRowKeys?.length === 0) && (
                <div className="page-master__filter-wrapper d-flex align-items-center justify-content-between">
                  <div className="page-master__filter d-flex align-items-center justify-content-start">
                    <div className="d-flex align-items-center">
                      <div className="">
                        <AdvanceDateRangeFilterMaster
                          value={[
                            modelFilter?.startedAt?.greaterEqual as any,
                            modelFilter?.startedAt?.lessEqual as any,
                          ]}
                          placeholder={[
                            translate("general.filter.startDate"),
                            translate("general.filter.endDate"),
                          ]}
                          onChange={handleChangeDateMasterFilter({
                            fieldName: nameof(list[0].startedAt),
                            fieldType: ["greaterEqual", "lessEqual"],
                          })}
                          label={translate("semesters.startedAt")}
                          translate={translate}
                        />
                      </div>
                      <div className="">
                        <AdvanceDateRangeFilterMaster
                          value={[
                            modelFilter?.endedAt?.greaterEqual as any,
                            modelFilter?.endedAt?.lessEqual as any,
                          ]}
                          placeholder={[
                            translate("general.filter.startDate"),
                            translate("general.filter.endDate"),
                          ]}
                          onChange={handleChangeDateMasterFilter({
                            fieldName: nameof(list[0].endedAt),
                            fieldType: ["greaterEqual", "lessEqual"],
                          })}
                          label={translate("semesters.endedAt")}
                          translate={translate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="page-master__filter-action-search d-flex align-items-center">
                    <Button
                      type="icon-only-ghost"
                      icon={<SettingsAdjust16 />}
                      onClick={() => setVisible(true)}
                      className="btn--xl"
                    />
                    <InputSearch
                      value={filter?.search}
                      classFilter={SemesterFilter}
                      placeHolder={translate("general.placeholder.search")}
                      onChange={handleChangeInputSearch}
                    />
                  </div>

                  <div className="page-master__actions  d-flex align-items-center justify-content-start">
                    <div className="page-master__filter-action d-flex align-items-center">
                      <Button
                        type="primary"
                        className="btn--lg"
                        icon={<Add16 />}
                        onClick={() => handleOpenDetailModal(null)}
                      >
                        {translate("general.actions.create")}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="page-master__content-table">
              <ActionBarComponent
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
              >
                <Button
                  icon={<TrashCan16 />}
                  type="ghost-primary"
                  className="btn--lg"
                  disabled={!canBulkAction}
                  onClick={() => handleBulkAction(selectedRowKeys)}
                >
                  {translate("general.actions.delete")}
                </Button>
              </ActionBarComponent>
              <StandardTable
                rowKey={nameof(list[0].id)}
                columns={columns}
                dataSource={list}
                isDragable={true}
                tableSize={"md"}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                spinning={loadingList}
                scroll={{ y: 500 }}
                bordered
              />

              <Pagination
                skip={filter?.skip}
                take={filter?.take}
                total={count}
                onChange={handlePagination}
                //canChangePageSize={false}
              />
            </div>
          </div>
        </div>
      </Spin>

      {visible && (
        <SemesterAdvanceFilter
          visible={visible}
          handleClose={handleCancel}
          visibleFooter={true}
          loading={false}
          size={"sm"}
          filter={filter}
          setVisible={setVisible}
          handleChangeAllFilter={handleChangeAllFilter}
        />
      )}
      {isOpenDetailModal && (
        <SemesterDetailDrawer
          model={model}
          visible={isOpenDetailModal}
          handleSave={handleSaveModel}
          handleCancel={handleCloseDetailModal}
          handleChangeSingleField={handleChangeSingleField}
          handleChangeDateField={handleChangeDateField}
          dispatch={dispatchModal}
          loading={loadingModel}
          visibleFooter={true}
        />
      )}
    </>
  );
}
export default SemesterMaster;
