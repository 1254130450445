import TrashCan16 from "@carbon/icons-react/es/trash-can/16";
import { Modal } from "antd";
import { ColumnProps } from "antd/lib/table";
import { Student, StudentFilter } from "models/Student";
import { CourseSection } from "models/CourseSection";
import React, { ChangeEvent, RefObject } from "react";
import { useTranslation } from "react-i18next";
import { OneLineText } from "react3l-ui-library";
import {
  FilterAction,
  filterReducer,
  filterService,
} from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import nameof from "ts-nameof.macro";
import { utilService } from "services/common-services/util-service";
import { renderMasterIndex } from "helpers/table";
import _ from "lodash";
import { webService } from "services/common-services/web-service";
import { generalLanguageKeys } from "config/language-keys";
import { courseSectionRepository } from "repositories/course-section-repository";
import LayoutHeader from "components/LayoutHeader";
import LayoutCell from "components/LayoutCell";
import { formatNumber } from "helpers/number";
import TruncateCell from "components/TruncateCell";

export function useCourseSectionStudentTable(
  model: CourseSection,
  handleChangeAllField: (data: CourseSection) => void
) {
  const [translate] = useTranslation();
  const [subscription] = webService.useSubscription();
  const [contents, setContents] = React.useState<Student[]>([]);
  const [tableFilter, dispatchTableFilter] = React.useReducer<
    React.Reducer<StudentFilter, FilterAction<StudentFilter>>
  >(filterReducer, new StudentFilter());

  const { handleChangeAllFilter: handleChangeTableFilter } =
    filterService.useFilter(tableFilter, dispatchTableFilter);

  const {
    list,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
    handleFilter,
  } = listService.useLocalListWithRowSelection(contents, tableFilter);

  React.useEffect(() => {
    if (
      model?.courseSectionStudentMappings &&
      model?.courseSectionStudentMappings?.length > 0
    ) {
      setContents(model?.courseSectionStudentMappings);
    } else setContents([]);
  }, [model?.courseSectionStudentMappings]);

  const handleDeleteStudent = React.useCallback(
    (content) => {
      const listStudent = contents.filter((item) => {
        return item.key !== content?.key;
      });
      setContents([...listStudent]);
      model.courseSectionStudentMappings = [...listStudent];
      handleChangeAllField(model);
      handleFilter(listStudent);
    },
    [contents, handleChangeAllField, handleFilter, model]
  );
  const handleDeleteItems = React.useCallback(() => {
    const cloneList = _.cloneDeep(list);
    const originalContent = _.cloneDeep(model?.courseSectionStudentMappings);
    const filteredIds = cloneList.map((item) => item.studentId);
    const notFilteredList = originalContent.filter(
      (item) => !filteredIds.includes(item.studentId)
    );
    const listStudent = cloneList.filter((item) => {
      return !selectedRowKeys?.includes(item?.studentId);
    });
    const mergeList = utilService.uniqueArray(
      [...notFilteredList, ...listStudent],
      "studentId"
    );
    handleFilter(mergeList);
    setContents([...mergeList]);
    model.courseSectionStudentMappings = [...mergeList];
    handleChangeAllField(model);
    setSelectedRowKeys([]);
  }, [
    handleChangeAllField,
    handleFilter,
    list,
    model,
    selectedRowKeys,
    setSelectedRowKeys,
  ]);

  const handleChangeSearchTable = React.useCallback(
    (value: string) => {
      handleChangeTableFilter({ ...tableFilter, search: value });
    },
    [handleChangeTableFilter, tableFilter]
  );

  const importButtonRef: RefObject<HTMLInputElement> =
    React.useRef<HTMLInputElement>(null);

  const handleClick = React.useCallback(() => {
    importButtonRef.current.value = null;
  }, []);

  const handleImportList = React.useCallback(() => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files.length > 0) {
        const file: File = event.target.files[0];

        subscription.add(
          courseSectionRepository.importMapping(file).subscribe({
            next: (res: any) => {
              Modal.success({
                content: translate(generalLanguageKeys.toasts.success),
              });
            },
            error: (error) => {
              Modal.error({
                content: error.response?.data,
              });
            },
          })
        );
      }
      handleChangeSearchTable(undefined);
    };
  }, [handleChangeSearchTable, subscription, translate]);

  const studentCourseSectionMappingColumns: ColumnProps<Student>[] =
    React.useMemo(
      () => [
        {
          title: (
            <LayoutHeader
              orderType="left"
              title={translate("general.columns.index")}
              position="center"
            />
          ),
          key: "index",
          width: 60,
          render(...params: [number, Student, number]) {
            const index = renderMasterIndex<Student>()(...params);
            return (
              <LayoutCell orderType="left" tableSize="md" position="center">
                <OneLineText value={formatNumber(index)} />
              </LayoutCell>
            );
          },
        },
        {
          title: (
            <LayoutHeader
              orderType="left"
              title={translate(
                "courseSections.courseSectionStudentMappings.code"
              )}
            />
          ),
          key: "code",
          dataIndex: "student",
          width: "20%",
          render(...params: [Student, Student, number]) {
            return <TruncateCell renderContent={params[0]?.code} />;
          },
        },

        {
          title: (
            <LayoutHeader
              orderType="left"
              title={translate(
                "courseSections.courseSectionStudentMappings.name"
              )}
            />
          ),
          key: "name",
          dataIndex: "student",
          render(...params: [Student, Student, number]) {
            return <TruncateCell renderContent={params[0]?.name} />;
          },
        },
        {
          title: (
            <LayoutHeader
              orderType="left"
              title={translate(
                "courseSections.courseSectionStudentMappings.cohort"
              )}
            />
          ),
          width: "10%",
          key: "cohort",
          dataIndex: "student",
          render(...params: [Student, Student, number]) {
            return <TruncateCell renderContent={params[0]?.cohort?.name} />;
          },
        },
        {
          title: (
            <LayoutHeader
              orderType="left"
              title={translate(
                "courseSections.courseSectionStudentMappings.className"
              )}
            />
          ),
          key: "className",
          dataIndex: "student",
          width: "20%",
          render(...params: [Student, Student, number]) {
            return <TruncateCell renderContent={params[0]?.className} />;
          },
        },
        {
          title: (
            <LayoutHeader
              orderType="left"
              title={translate(
                "courseSections.courseSectionStudentMappings.major"
              )}
            />
          ),
          key: "major",
          dataIndex: "student",
          width: "20%",
          render(...params: [Student, Student, number]) {
            return <TruncateCell renderContent={params[0]?.major?.name} />;
          },
        },
        {
          title: translate("general.actions.label"),
          key: "action",
          dataIndex: nameof(list[0].id),
          fixed: "right",
          width: 80,
          align: "center",
          render(...params: [string, Student, number]) {
            return (
              <div className="text-center" style={{ cursor: "pointer" }}>
                <TrashCan16
                  color="#da1e28"
                  onClick={() => handleDeleteStudent(params[1])}
                />
              </div>
            );
          },
        },
      ],
      [handleDeleteStudent, list, translate]
    );

  return {
    tableFilter,
    list,
    selectedRowKeys,
    rowSelection,
    studentCourseSectionMappingColumns,
    importButtonRef,
    handleChangeSearchTable,
    handleDeleteItems,
    handleImportList,
    handleClick,
  };
}
