import type { RangePickerProps } from "antd/lib/date-picker";
import classNames from "classnames";
import { Moment } from "moment";
import React, { RefObject } from "react";
import { TFunction } from "i18next";
import { Model } from "react3l-common";
import "./AdvanceDateRangeFilterMaster.scss";
import { utilService } from "services/common-services/util-service";
import { ChevronDown16 } from "@carbon/icons-react";
import { DateRange } from "react3l-ui-library";
import { BORDER_TYPE } from "config/consts";

class ListDate extends Model {
  id?: number;
  name?: string;
  code?: string;
}

interface AdvanceDateRangeFilterMasterFilterProps {
  /**Value [fromDate, toDate] users select*/
  value?: [Moment, Moment];
  /**Use to format the date selected*/
  dateFormat?: string[];
  /**Handle the change value of the component*/
  onChange?: (item?: any, value?: [Moment, Moment]) => void;
  /**Use to custom style the component*/
  className?: string;
  /**Not allow to handle change the component*/
  disabled?: boolean;
  /**Prop of DateRange Component*/
  typeCustomDate?: BORDER_TYPE;
  /**Control the size of the component*/
  isSmall?: boolean;
  /**Label for current field*/
  label?: string;
  /**Selected value of list value suggest*/
  activeItem?: any;
  /**Prop of component InputSelect*/
  inputType?: BORDER_TYPE;
  /**Prop of component InputSelect*/
  placeHolderSelect?: string;
  /**Append this component to body*/
  appendToBody?: boolean;
  /**Provide a translate function*/
  translate?: TFunction;
  /**Placeholder of the component*/
  placeholder?: [string, string];
  /** Custom background color for component: "white" || "gray" */
  bgColor?: "white" | "gray";
}

function AdvanceDateRangeFilterMasterFilter(
  props: AdvanceDateRangeFilterMasterFilterProps & RangePickerProps
) {
  const {
    value,
    onChange,
    label,
    className,
    appendToBody,
    typeCustomDate,
    isSmall,
    placeholder,
  } = props;

  const [isExpandDate, setExpandDate] = React.useState<boolean>(false);

  const wrapperRef: RefObject<HTMLDivElement> =
    React.useRef<HTMLDivElement>(null);

  const panelRef = React.useRef(null);
  const listRef = React.useRef(null);
  const [appendToBodyStyle, setAppendToBodyStyle] = React.useState({});

  const internalValue: any = React.useMemo(() => {
    return value && value.length > 0
      ? [
          typeof value[0] === "string"
            ? utilService.toMomentDate(value[0])
            : value[0],
          typeof value[1] === "string"
            ? utilService.toMomentDate(value[1])
            : value[1],
        ]
      : [null, null];
  }, [value]);

  const handleChange = React.useCallback(
    (values: [Moment, Moment]) => {
      onChange([values[0]?.startOf("day"), values[1]?.endOf("day")]);
    },
    [onChange]
  );

  const handleCloseAdvanceFilterMaster = React.useCallback(() => {
    // if (internalValue[0] || internalValue[1]) {
    //   return;
    // }
    setExpandDate(false);
  }, []);

  const handleClickCustomDate = React.useCallback(() => {
    setExpandDate(true);
  }, []);

  const handleGetRef = React.useCallback(() => {
    return document.getElementsByClassName("date-range-master");
  }, []);

  const handleOpenChange = React.useCallback(() => {
    setTimeout(() => {
      panelRef.current = handleGetRef()[0];
    }, 100);
  }, [handleGetRef]);

  utilService.useClickOutsideMultiple(
    wrapperRef,
    panelRef,
    handleCloseAdvanceFilterMaster
  );

  React.useEffect(() => {
    if (appendToBody) {
      const currentPosition = wrapperRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - currentPosition.bottom;
      if (spaceBelow <= 200) {
        setTimeout(() => {
          setAppendToBodyStyle({
            position: "fixed",
            bottom: spaceBelow + wrapperRef.current.clientHeight,
            left: currentPosition.left,
            maxWidth: wrapperRef.current.clientWidth,
          });
        }, 100);
      } else {
        setAppendToBodyStyle({
          position: "fixed",
          top: currentPosition.top + wrapperRef.current.clientHeight,
          left: currentPosition.left,
          maxWidth: wrapperRef.current.clientWidth,
        });
      }
    }
  }, [appendToBody]);

  return (
    <div
      className={classNames(
        "advance-date-range-filter-master__wrapper",
        className
      )}
      ref={wrapperRef}
    >
      <div
        className={classNames(
          "advance-date-range-filter-master__container p-l--sm p-t--xs p-r--xs p-b--xs",
          {
            "p-b---active": value && value?.length > 0 && value[0],
            "filter-bg": isExpandDate,
          }
        )}
        onClick={handleClickCustomDate}
      >
        <div
          className={classNames({
            "filter-active": value && value?.length > 0 && value[0],
          })}
        >
          <div className="advance-date-range-filter-master__title">
            <span className="filter-title"> {label}</span>
            <ChevronDown16 />
          </div>
        </div>
      </div>
      <div
        id="list-container"
        className={classNames("date-range-filter-master__list-container")}
        style={appendToBodyStyle}
        ref={listRef}
      >
        {isExpandDate && (
          <>
            <DateRange
              {...props}
              label={""}
              type={typeCustomDate}
              isSmall={isSmall}
              onChange={handleChange}
              value={internalValue}
              getPopupContainer={
                !appendToBody
                  ? () => document.getElementById("list-container")
                  : null
              }
              placeholder={placeholder}
              dropdownClassName="date-range-master"
              onOpenChange={handleOpenChange}
            />
          </>
        )}
      </div>
    </div>
  );
}

AdvanceDateRangeFilterMasterFilter.defaultProps = {
  dateFormat: ["DD/MM/YYYY", "YYYY/MM/DD"],
  placeholder: ["Từ ngày", "Đến ngày"],
  placeHolderSelect: "",
  appendToBody: false,
  bgColor: "white",
};

export default AdvanceDateRangeFilterMasterFilter;
