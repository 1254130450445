import { join } from "path";

export const ROOT_ROUTE: string = process.env.PUBLIC_URL;
export const LOGIN_ROUTE: string = "/login";
export const LOGOUT_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/logout"
  : "/logout";
export const FORBIDENT_ROUTE: string = "/403";
export const NOT_FOUND_ROUTE: string = "/404";

export const APP_USER_MANAGEMENT_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/app-user-management"
  : "/app-user-management";

export const CATEGORY_MANAGEMENT_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/category-management"
  : "/category-management";

export const APP_USER_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/app-user"
  : "/app-user";
export const APP_USER_MASTER_ROUTE: string = join(
  APP_USER_ROUTE,
  "app-user-master"
);
export const APP_USER_DETAIL_ROUTE: string = join(
  APP_USER_ROUTE,
  "app-user-detail"
);

export const PROFILE_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/profile"
  : "/profile";
export const PROFILE_MASTER_ROUTE: string = join(
  PROFILE_ROUTE,
  "profile-master"
);
export const PROFILE_DETAIL_ROUTE: string = join(
  PROFILE_ROUTE,
  "profile-detail"
);

export const MAJOR_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/major"
  : "/major";
export const MAJOR_MASTER_ROUTE: string = join(MAJOR_ROUTE, "major-master");
export const MAJOR_DETAIL_ROUTE: string = join(MAJOR_ROUTE, "major-detail");

export const SUBJECT_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/subject"
  : "/subject";
export const SUBJECT_MASTER_ROUTE: string = join(
  SUBJECT_ROUTE,
  "subject-master"
);
export const SUBJECT_DETAIL_ROUTE: string = join(
  SUBJECT_ROUTE,
  "subject-detail"
);

export const STUDENT_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/student"
  : "/student";
export const STUDENT_MASTER_ROUTE: string = join(
  STUDENT_ROUTE,
  "student-master"
);
export const STUDENT_DETAIL_ROUTE: string = join(
  STUDENT_ROUTE,
  "student-detail"
);

export const SEMESTER_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/semester"
  : "/semester";
export const SEMESTER_MASTER_ROUTE: string = join(
  SEMESTER_ROUTE,
  "semester-master"
);
export const SEMESTER_DETAIL_ROUTE: string = join(
  SEMESTER_ROUTE,
  "semester-detail"
);

export const COHORT_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/cohort"
  : "/cohort";
export const COHORT_MASTER_ROUTE: string = join(COHORT_ROUTE, "cohort-master");
export const COHORT_DETAIL_ROUTE: string = join(COHORT_ROUTE, "cohort-detail");

export const COURSE_SECTION_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/course-section"
  : "/course-section";
export const COURSE_SECTION_MASTER_ROUTE: string = join(
  COURSE_SECTION_ROUTE,
  "course-section-master"
);
export const COURSE_SECTION_DETAIL_ROUTE: string = join(
  COURSE_SECTION_ROUTE,
  "course-section-detail"
);

export const ROLL_CALL_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/roll-call"
  : "/roll-call";
export const ROLL_CALL_MASTER_ROUTE: string = join(
  ROLL_CALL_ROUTE,
  "roll-call-master"
);
export const ROLL_CALL_DETAIL_ROUTE: string = join(
  ROLL_CALL_ROUTE,
  "roll-call-detail"
);

export const COURSE_SECTION_ROLL_CALL_DETAIL_ROUTE: string = join(
  ROLL_CALL_ROUTE,
  "course-section-roll-call-detail"
);

export const REPORT_ROUTE: string = ROOT_ROUTE
  ? ROOT_ROUTE + "/report"
  : "/report";

export const REPORT_BY_COURSE_SECTION_ROUTE: string = join(
  REPORT_ROUTE,
  "report-by-course-section"
);
export const REPORT_BY_STUDENT_ROUTE: string = join(
  REPORT_ROUTE,
  "report-by-student"
);
export const REPORT_BY_MAJOR_ROUTE: string = join(
  REPORT_ROUTE,
  "report-by-major"
);
export const REPORT_BY_COHORT_ROUTE: string = join(
  REPORT_ROUTE,
  "report-by-cohort"
);
export const REPORT_ROLL_CALL_DETAIL_ROUTE: string = join(
  REPORT_ROUTE,
  "report-roll-call-detail"
);
export const REPORT_NOT_ROLL_CALL_ROUTE: string = join(
  REPORT_ROUTE,
  "report-not-roll-call"
);
