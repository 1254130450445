import { Cohort } from "models/Cohort";
import { CourseSection } from "models/CourseSection";
import { Semester } from "models/Semester";
import { Subject } from "models/Subject";
import { Model } from "react3l-common";
import { Field } from "react3l-decorators";

export class Report extends Model {
  @Field(Number)
  public stt?: number;

  @Field(Number)
  public rollCallId?: number;

  @Field(Number)
  public courseSectionId?: number;

  @Field(Number)
  public subjectId?: number;

  @Field(Number)
  public semesterId?: number;

  @Field(Number)
  public indexOfLesson?: number;

  @Field(Number)
  public numberOfStudent?: number;

  @Field(Number)
  public numberOfInClass?: number;

  @Field(Number)
  public numberOfLate?: number;

  @Field(Number)
  public numberOfAbsent?: number;

  @Field(Number)
  public numberOfAbsentP?: number;

  @Field(String)
  public sAppUserNames?: string;

  public courseSection?: CourseSection;

  public subject?: Subject;

  public cohort?: Cohort;

  public semester?: Semester;

  public appUserNames?: string[];
}
