import { DateFilter, GuidFilter, IdFilter } from "react3l-advanced-filters";
import { ModelFilter } from "react3l-common";

export class ReportFilter extends ModelFilter {
  public subjectId?: IdFilter = new IdFilter();
  public cohortId?: IdFilter = new IdFilter();
  public semesterId?: IdFilter = new IdFilter();
  public majorId?: IdFilter = new IdFilter();
  public organizationId?: IdFilter = new IdFilter();
  public studentId?: IdFilter = new IdFilter();
  public appUserId?: IdFilter = new IdFilter();
  public teacherId?: IdFilter = new IdFilter();
  public courseSectionId?: IdFilter = new IdFilter();
  public date?: DateFilter = new DateFilter();
  public updatedAt?: DateFilter = new DateFilter();
  public rowId?: GuidFilter = new GuidFilter();
  public search?: string = null;
}
