import { DateFilter, IdFilter, StringFilter } from "react3l-advanced-filters";
import { ModelFilter } from "react3l-common";

export class CohortFilter extends ModelFilter {
  public id?: IdFilter = new IdFilter();
  public code?: StringFilter = new StringFilter();
  public name?: StringFilter = new StringFilter();
  public endedAt?: DateFilter = new DateFilter();
  public startedAt?: DateFilter = new DateFilter();
  public search?: string = null;
}
