/* begin general import */
import { Download16 } from "@carbon/icons-react";
import { PaginationProps, Spin } from "antd";
import { ColumnProps } from "antd/lib/table";
import PageHeader from "components/PageHeader/PageHeader";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AdvanceIdFilterMaster,
  Button,
  OneLineText,
  Pagination,
  TagFilter,
} from "react3l-ui-library";
import { filterService } from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { queryStringService } from "services/page-services/query-string-service";
import {
  getAntOrderType,
  tableService,
} from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import { IdFilter } from "react3l-advanced-filters";
import { renderMasterIndex } from "helpers/table";
import { importExportService } from "services/page-services/import-export-service";
import { reportRepository } from "repositories/report-repository";
import { ReportFilter, Report } from "models/Report";
import { formatNumber } from "helpers/number";
import StandardTable from "components/StandardTable";
import AdvanceDateRangeFilterMaster from "components/AdvanceDateRangeFilter";
import LayoutHeader from "components/LayoutHeader";
import LayoutCell from "components/LayoutCell";
import TruncateCell from "components/TruncateCell";
import { Cohort, CohortFilter } from "models/Cohort";
import { Major, MajorFilter } from "models/Major";
/* end individual import */

function ReportByCohort() {
  const [translate] = useTranslation();
  const [modelFilter, dispatch] = queryStringService.useQueryString(
    ReportFilter,
    { skip: 0, take: 10 }
  );

  const {
    value: filter,
    handleChangeAllFilter,
    handleChangeSelectFilter,
    handleChangeDateFilter,
  } = filterService.useFilter(modelFilter, dispatch);

  const { list, count, loadingList } = listService.useList(
    reportRepository.listReportByCohort,
    reportRepository.countReportByCohort,
    filter,
    dispatch
  );

  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeAllFilter
  );

  const { loading: loadingExport, handleListExport } =
    importExportService.useExport();

  const pagination: PaginationProps = tableService.usePagination(filter, count);

  const columns: ColumnProps<Report>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
            position="center"
          />
        ),
        key: "index",
        fixed: "left",
        width: 60,
        render(...params: [number, Report, number]) {
          const index = renderMasterIndex<Report>(pagination)(...params);
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={formatNumber(index)} />
            </LayoutCell>
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "cohort"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("reports.cohort")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].cohort),
        dataIndex: nameof(list[0].cohort),
        sorter: true,
        sortOrder: getAntOrderType<Report, ReportFilter>(
          filter,
          nameof(list[0].cohort)
        ),
        render(...params: [Cohort, Report, number]) {
          return <TruncateCell renderContent={params[0]?.name} />;
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "major"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("reports.major")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].major),
        dataIndex: nameof(list[0].major),
        sorter: true,
        sortOrder: getAntOrderType<Report, ReportFilter>(
          filter,
          nameof(list[0].major)
        ),
        width: "20%",
        render(...params: [Major, Report, number]) {
          return <TruncateCell renderContent={params[0]?.name} />;
        },
      },
      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("reports.numberOfStudent")}
              position="right"
            />
          );
        },
        key: nameof(list[0].numberOfStudent),
        dataIndex: nameof(list[0].numberOfStudent),
        width: "12%",
        render(...params: [number, Report, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md" position="right">
              <OneLineText value={formatNumber(params[0])} />
            </LayoutCell>
          );
        },
      },
      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("reports.numberOfInClass")}
              position="right"
            />
          );
        },
        key: nameof(list[0].numberOfInClass),
        dataIndex: nameof(list[0].numberOfInClass),
        width: "12%",
        render(...params: [number, Report, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md" position="right">
              <OneLineText
                value={`${formatNumber(params[0])} (${formatNumber(
                  params[1]?.inClassRate
                )}%)`}
              />
            </LayoutCell>
          );
        },
      },
      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("reports.numberOfLate")}
              position="right"
            />
          );
        },
        key: nameof(list[0].numberOfLate),
        dataIndex: nameof(list[0].numberOfLate),
        width: "12%",
        render(...params: [number, Report, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md" position="right">
              <OneLineText
                value={`${formatNumber(params[0])} (${formatNumber(
                  params[1]?.lateRate
                )}%)`}
              />
            </LayoutCell>
          );
        },
      },
      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("reports.numberOfAbsent")}
              position="right"
            />
          );
        },
        key: nameof(list[0].numberOfAbsent),
        dataIndex: nameof(list[0].numberOfAbsent),
        width: "12%",
        render(...params: [number, Report, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md" position="right">
              <OneLineText
                value={`${formatNumber(params[0])} (${formatNumber(
                  params[1]?.absentRate
                )}%)`}
              />
            </LayoutCell>
          );
        },
      },
      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("reports.numberOfAbsentP")}
              position="right"
            />
          );
        },
        key: nameof(list[0].numberOfAbsentP),
        dataIndex: nameof(list[0].numberOfAbsentP),
        width: "12%",
        render(...params: [number, Report, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md" position="right">
              <OneLineText
                value={`${formatNumber(params[0])} (${formatNumber(
                  params[1]?.absentPRate
                )}%)`}
              />
            </LayoutCell>
          );
        },
      },
    ],
    [translate, pagination, list, filter]
  );

  return (
    <>
      <Spin spinning={loadingExport || loadingList}>
        <div className="page-content">
          <PageHeader
            title={translate("reports.reportByCohorts.master.header")}
            breadcrumbItems={[
              translate("menu.title.report"),
              translate("reports.reportByCohorts.master.header"),
            ]}
          />

          <div className="page page-master m-l--sm m-r--xxl m-b--xxs">
            <div className="page-master__title p-l--sm p-t--xs p-b--xs">
              {translate("reports.reportByCohorts.master.header")}
            </div>
            <div className="page-master__content">
              <div className="page-master__tag-filter">
                <TagFilter
                  value={filter}
                  translate={translate}
                  keyTranslate={"reports"}
                  handleChangeFilter={handleChangeAllFilter}
                  onClear={() => {
                    return 0;
                  }}
                />
              </div>

              <div className="page-master__filter-wrapper d-flex align-items-center justify-content-between">
                <div className="page-master__filter d-flex align-items-center justify-content-start">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <AdvanceIdFilterMaster
                        value={filter["cohortId"]["equal"]}
                        placeHolder={translate("reports.placeholder.cohort")}
                        classFilter={CohortFilter}
                        onChange={handleChangeSelectFilter({
                          fieldName: "cohort",
                          fieldType: "equal",
                          classFilter: IdFilter,
                        })}
                        getList={reportRepository.filterListCohort}
                        title={translate("reports.cohort")}
                      />
                    </div>

                    <div className="">
                      <AdvanceIdFilterMaster
                        value={filter["majorId"]["equal"]}
                        placeHolder={translate("reports.placeholder.major")}
                        classFilter={MajorFilter}
                        onChange={handleChangeSelectFilter({
                          fieldName: "major",
                          fieldType: "equal",
                          classFilter: IdFilter,
                        })}
                        getList={reportRepository.filterListMajor}
                        title={translate("reports.major")}
                      />
                    </div>

                    <AdvanceDateRangeFilterMaster
                      onChange={(value?: [any, any]) => {
                        const internalValue =
                          !value[0] && !value[1] ? undefined : value;
                        handleChangeDateFilter({
                          fieldName: "date",
                          fieldType: ["greaterEqual", "lessEqual"],
                        })(internalValue);
                      }}
                      value={[
                        filter?.date?.greaterEqual as any,
                        filter?.date?.lessEqual as any,
                      ]}
                      placeholder={[
                        translate("general.filter.startDate"),
                        translate("general.filter.endDate"),
                      ]}
                      label={translate("reports.date")}
                    />
                  </div>
                </div>
                <div className="page-master__actions  d-flex align-items-center justify-content-start">
                  <div className="page-master__filter-action d-flex align-items-center">
                    <Button
                      type="primary"
                      icon={<Download16 />}
                      onClick={handleListExport(
                        filter,
                        reportRepository.exportReportByCohort
                      )}
                      className="btn--lg"
                    >
                      {translate("reports.exportReportByCohort")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-master__content-table">
              <StandardTable
                rowKey={nameof(list[0].key)}
                columns={columns}
                dataSource={list}
                isDragable={true}
                tableSize={"md"}
                onChange={handleTableChange}
                spinning={loadingList}
                bordered
                scroll={{ y: 500 }}
              />

              <Pagination
                skip={filter?.skip}
                take={filter?.take}
                total={count}
                onChange={handlePagination}
              />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
}
export default ReportByCohort;
