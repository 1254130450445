import { Repository } from "react3l-common";
import { kebabCase } from "lodash";
import { httpConfig } from "config/http";
import { BASE_API_URL } from "config/consts";
import { Observable } from "rxjs";
import { AxiosResponse } from "axios";
import nameof from "ts-nameof.macro";
import { API_COURSE_SECTION_PREFIX } from "config/api-consts";
import { CourseSection, CourseSectionFilter } from "models/CourseSection";
import { Status, StatusFilter } from "models/Status";
import { Subject, SubjectFilter } from "models/Subject";
import { Organization, OrganizationFilter } from "models/Organization";
import { AppUser, AppUserFilter } from "models/AppUser";
import { Semester, SemesterFilter } from "models/Semester";
import { StudentFilter } from "models/Student";
import { Weekday, WeekdayFilter } from "models/Weekday";
import { ClassShift, ClassShiftFilter } from "models/ClassShift";
import { RollCall, RollCallFilter } from "models/RollCall";
import { Cohort, CohortFilter } from "models/Cohort";

export type KeyType = string | number;

export class CourseSectionRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = new URL(API_COURSE_SECTION_PREFIX, BASE_API_URL).href;
  }

  public count = (
    courseSectionFilter?: CourseSectionFilter
  ): Observable<number> => {
    return this.http
      .post<number>(kebabCase(nameof(this.count)), courseSectionFilter)
      .pipe(Repository.responseDataMapper<number>());
  };

  public list = (
    courseSectionFilter?: CourseSectionFilter
  ): Observable<CourseSection[]> => {
    return this.http
      .post<CourseSection[]>(kebabCase(nameof(this.list)), courseSectionFilter)
      .pipe(Repository.responseMapToList<CourseSection>(CourseSection));
  };

  public countStudent = (studentFilter?: StudentFilter): Observable<number> => {
    return this.http
      .post<number>(kebabCase(nameof(this.countStudent)), studentFilter)
      .pipe(Repository.responseDataMapper<number>());
  };

  public listStudent = (
    studentFilter?: StudentFilter
  ): Observable<CourseSection[]> => {
    return this.http
      .post<CourseSection[]>(kebabCase(nameof(this.listStudent)), studentFilter)
      .pipe(Repository.responseMapToList<CourseSection>(CourseSection));
  };

  public get = (id: number | string): Observable<CourseSection> => {
    return this.http
      .post<CourseSection>(kebabCase(nameof(this.get)), { id })
      .pipe(Repository.responseMapToModel<CourseSection>(CourseSection));
  };

  public create = (courseSection: CourseSection): Observable<CourseSection> => {
    return this.http
      .post<CourseSection>(kebabCase(nameof(this.create)), courseSection)
      .pipe(Repository.responseMapToModel<CourseSection>(CourseSection));
  };

  public update = (courseSection: CourseSection): Observable<CourseSection> => {
    return this.http
      .post<CourseSection>(kebabCase(nameof(this.update)), courseSection)
      .pipe(Repository.responseMapToModel<CourseSection>(CourseSection));
  };

  public delete = (courseSection: CourseSection): Observable<CourseSection> => {
    return this.http
      .post<CourseSection>(kebabCase(nameof(this.delete)), courseSection)
      .pipe(Repository.responseMapToModel<CourseSection>(CourseSection));
  };

  public bulkDelete = (idList: KeyType[]): Observable<void> => {
    return this.http
      .post(kebabCase(nameof(this.bulkDelete)), idList)
      .pipe(Repository.responseDataMapper());
  };

  public save = (courseSection: CourseSection): Observable<CourseSection> => {
    return courseSection.id
      ? this.update(courseSection)
      : this.create(courseSection);
  };

  public filterListGeneralClass = (
    subjectFilter: SubjectFilter
  ): Observable<Subject[]> => {
    return this.http
      .post<Subject[]>(
        kebabCase(nameof(this.filterListGeneralClass)),
        subjectFilter
      )
      .pipe(Repository.responseMapToList<Subject>(Subject));
  };

  public filterListCohort = (filter: CohortFilter): Observable<Cohort[]> => {
    return this.http
      .post<Cohort[]>(kebabCase(nameof(this.filterListCohort)), filter)
      .pipe(Repository.responseMapToList<Cohort>(Cohort));
  };

  public singleListStatus = (): Observable<Status[]> => {
    return this.http
      .post<Status[]>(
        kebabCase(nameof(this.singleListStatus)),
        new StatusFilter()
      )
      .pipe(Repository.responseMapToList<Status>(Status));
  };

  public filterListStatus = (): Observable<Status[]> => {
    return this.http
      .post<Status[]>(
        kebabCase(nameof(this.filterListStatus)),
        new StatusFilter()
      )
      .pipe(Repository.responseMapToList<Status>(Status));
  };

  public singleListSubject = (
    subjectFilter: SubjectFilter
  ): Observable<Subject[]> => {
    return this.http
      .post<Subject[]>(kebabCase(nameof(this.singleListSubject)), subjectFilter)
      .pipe(Repository.responseMapToList<Subject>(Subject));
  };
  public filterListSubject = (
    subjectFilter: SubjectFilter
  ): Observable<Subject[]> => {
    return this.http
      .post<Subject[]>(kebabCase(nameof(this.filterListSubject)), subjectFilter)
      .pipe(Repository.responseMapToList<Subject>(Subject));
  };

  public singleListAppUser = (
    appUserFilter: AppUserFilter
  ): Observable<AppUser[]> => {
    return this.http
      .post<AppUser[]>(kebabCase(nameof(this.singleListAppUser)), appUserFilter)
      .pipe(Repository.responseMapToList<AppUser>(AppUser));
  };
  public filterListAppUser = (
    appUserFilter: AppUserFilter
  ): Observable<AppUser[]> => {
    return this.http
      .post<AppUser[]>(kebabCase(nameof(this.filterListAppUser)), appUserFilter)
      .pipe(Repository.responseMapToList<AppUser>(AppUser));
  };

  public singleListSemester = (
    semesterFilter: SemesterFilter
  ): Observable<Semester[]> => {
    return this.http
      .post<Semester[]>(
        kebabCase(nameof(this.singleListSemester)),
        semesterFilter
      )
      .pipe(Repository.responseMapToList<Semester>(Semester));
  };
  public filterListSemester = (
    semesterFilter: SemesterFilter
  ): Observable<Semester[]> => {
    return this.http
      .post<Semester[]>(
        kebabCase(nameof(this.filterListSemester)),
        semesterFilter
      )
      .pipe(Repository.responseMapToList<Semester>(Semester));
  };

  public singleListWeekday = (
    weekdayFilter: WeekdayFilter
  ): Observable<Weekday[]> => {
    return this.http
      .post<Weekday[]>(kebabCase(nameof(this.singleListWeekday)), weekdayFilter)
      .pipe(Repository.responseMapToList<Weekday>(Weekday));
  };
  public filterListWeekday = (
    weekdayFilter: WeekdayFilter
  ): Observable<Weekday[]> => {
    return this.http
      .post<Weekday[]>(kebabCase(nameof(this.filterListWeekday)), weekdayFilter)
      .pipe(Repository.responseMapToList<Weekday>(Weekday));
  };

  public singleListClassShift = (
    classShiftFilter: ClassShiftFilter
  ): Observable<ClassShift[]> => {
    return this.http
      .post<ClassShift[]>(
        kebabCase(nameof(this.singleListClassShift)),
        classShiftFilter
      )
      .pipe(Repository.responseMapToList<ClassShift>(ClassShift));
  };
  public filterListClassShift = (
    classShiftFilter: ClassShiftFilter
  ): Observable<ClassShift[]> => {
    return this.http
      .post<ClassShift[]>(
        kebabCase(nameof(this.filterListClassShift)),
        classShiftFilter
      )
      .pipe(Repository.responseMapToList<ClassShift>(ClassShift));
  };

  public singleListOrganization = (
    organizationFilter: OrganizationFilter
  ): Observable<Organization[]> => {
    return this.http
      .post<Organization[]>(
        kebabCase(nameof(this.singleListOrganization)),
        organizationFilter
      )
      .pipe(Repository.responseMapToList<Organization>(Organization));
  };
  public filterListOrganization = (
    organizationFilter: OrganizationFilter
  ): Observable<Organization[]> => {
    return this.http
      .post<Organization[]>(
        kebabCase(nameof(this.filterListOrganization)),
        organizationFilter
      )
      .pipe(Repository.responseMapToList<Organization>(Organization));
  };

  public import = (
    file: File,
    name: string = nameof(file)
  ): Observable<void> => {
    const formData: FormData = new FormData();
    formData.append(name, file as Blob);
    return this.http
      .post<void>(kebabCase(nameof(this.import)), formData)
      .pipe(Repository.responseDataMapper<any>());
  };

  public importMapping = (
    file: File,
    name: string = nameof(file)
  ): Observable<void> => {
    const formData: FormData = new FormData();
    formData.append(name, file as Blob);
    return this.http
      .post<void>(kebabCase(nameof(this.importMapping)), formData)
      .pipe(Repository.responseDataMapper<any>());
  };

  public export = (filter: any): Observable<AxiosResponse<any>> => {
    return this.http.post("export", filter, {
      responseType: "arraybuffer",
    });
  };

  public exportTemplate = (): Observable<AxiosResponse<any>> => {
    return this.http.post(
      "export-template",
      {},
      {
        responseType: "arraybuffer",
      }
    );
  };

  public exportTemplateMapping = (): Observable<AxiosResponse<any>> => {
    return this.http.post(
      "export-template-mapping",
      {},
      {
        responseType: "arraybuffer",
      }
    );
  };

  // Điểm danh

  public countRollCall = (
    rollCallFilter?: RollCallFilter
  ): Observable<number> => {
    return this.http
      .post<number>(kebabCase(nameof(this.countRollCall)), rollCallFilter)
      .pipe(Repository.responseDataMapper<number>());
  };

  public listRollCall = (
    rollCallFilter?: RollCallFilter
  ): Observable<RollCall[]> => {
    return this.http
      .post<RollCall[]>(kebabCase(nameof(this.listRollCall)), rollCallFilter)
      .pipe(Repository.responseMapToList<RollCall>(RollCall));
  };

  public getRollCall = (filter: RollCallFilter): Observable<RollCall> => {
    return this.http
      .post<RollCall>(kebabCase(nameof(this.getRollCall)), filter)
      .pipe(Repository.responseMapToModel<RollCall>(RollCall));
  };

  public getDraftRollCall = (filter: RollCallFilter): Observable<RollCall> => {
    return this.http
      .post<RollCall>(kebabCase(nameof(this.getDraftRollCall)), filter)
      .pipe(Repository.responseMapToModel<RollCall>(RollCall));
  };

  public createRollCall = (rollCall: RollCall): Observable<RollCall> => {
    return this.http
      .post<RollCall>(kebabCase(nameof(this.createRollCall)), rollCall)
      .pipe(Repository.responseMapToModel<RollCall>(RollCall));
  };

  public cloneRollCall = (rollCall: RollCall): Observable<RollCall> => {
    return this.http
      .post<RollCall>(kebabCase(nameof(this.cloneRollCall)), rollCall)
      .pipe(Repository.responseMapToModel<RollCall>(RollCall));
  };

  public updateRollCall = (rollCall: RollCall): Observable<RollCall> => {
    return this.http
      .post<RollCall>(kebabCase(nameof(this.updateRollCall)), rollCall)
      .pipe(Repository.responseMapToModel<RollCall>(RollCall));
  };

  public saveRollCall = (rollCall: RollCall): Observable<RollCall> => {
    return rollCall.id
      ? this.updateRollCall(rollCall)
      : this.createRollCall(rollCall);
  };

  public deleteRollCall = (rollCall: RollCall): Observable<RollCall> => {
    return this.http
      .post<RollCall>(kebabCase(nameof(this.deleteRollCall)), rollCall)
      .pipe(Repository.responseMapToModel<RollCall>(RollCall));
  };
}

export const courseSectionRepository = new CourseSectionRepository();
