import { CourseSection } from "models/CourseSection";
import React, { useMemo } from "react";
import {
  FilterAction,
  filterReducer,
  filterService,
} from "services/page-services/filter-service";
import { GuidFilter, IdFilter, StringFilter } from "react3l-advanced-filters";
import { detailService } from "services/page-services/detail-service";
import { tableService } from "services/page-services/table-service";
import memoize from "fast-memoize";
import { Model } from "react3l-common";
import _ from "lodash";
import { Student, StudentFilter } from "models/Student";
import { courseSectionRepository } from "repositories/course-section-repository";

export function useCourseSectionStudentModal(
  model: CourseSection,
  setModel: (courseSection: CourseSection) => void
) {
  const [studentFilter, dispatchStudentFilter] = React.useReducer<
    React.Reducer<StudentFilter, FilterAction<StudentFilter>>
  >(filterReducer, { ...new StudentFilter(), skip: 0, take: 10 });

  const mappingField: [string, string] = React.useMemo(() => {
    return ["studentId", "student"];
  }, []);

  const {
    value: filter,
    handleChangeAllFilter: handleChangeStudentAllFilter,
    handleChangeInputSearch,
  } = filterService.useFilter(studentFilter, dispatchStudentFilter);

  const tmpFilter = React.useMemo(() => {
    return {
      ...filter,
      statusId: {
        equal: 1,
      },
    };
  }, [filter]);

  const mappingData = React.useCallback(
    (selectedList: Student[]) => {
      const list: any = selectedList.map((item: Student) => {
        return {
          courseSectionId: model?.id,
          studentId: item.id,
          student: item,
        };
      });
      return list;
    },
    [model]
  );

  const {
    open: visible,
    listMapping,
    countMapping,
    checkedKeys,
    spinning,
    handleOpenMapping,
    handleCloseMapping,
    handleSaveMapping: handleSaveMappingStudent,
    handleGetListMapping,
    handleCancelMapping,
    handleCheck,
    handleCheckAll,
  } = detailService.useMappingService(
    courseSectionRepository.listStudent,
    courseSectionRepository.countStudent,
    mappingData,
    tmpFilter,
    model.courseSectionStudentMappings,
    mappingField
  );

  const handleSaveMapping = React.useCallback(() => {
    const courseSectionStudentMappings = handleSaveMappingStudent();
    setModel({ ...model, courseSectionStudentMappings });
    handleChangeStudentAllFilter(new StudentFilter());
  }, [handleChangeStudentAllFilter, handleSaveMappingStudent, model, setModel]);

  const handleCloseMappingModal = React.useCallback(() => {
    handleChangeStudentAllFilter(new StudentFilter());
    handleCloseMapping();
  }, [handleChangeStudentAllFilter, handleCloseMapping]);

  const handleChangeStudentModalFilter = React.useCallback(
    (value) => {
      handleChangeStudentAllFilter(value);
      handleGetListMapping(value);
    },
    [handleChangeStudentAllFilter, handleGetListMapping]
  );

  const handleChangerRowSelection = React.useCallback(
    (record) => {
      const selected = checkedKeys?.includes(record?.id) ? false : true;

      handleCheck(record, selected);
    },
    [checkedKeys, handleCheck]
  );
  const rowSelection = useMemo(
    () => ({
      onSelect(record: Student, selected: boolean) {
        handleCheck(record, selected);
      },
      onSelectAll(
        selected: boolean,
        selectedRows: Student[],
        changeRows: Student[]
      ) {
        handleCheckAll(selected, selectedRows, changeRows);
      },
      selectedRowKeys: checkedKeys,
    }),
    [checkedKeys, handleCheck, handleCheckAll]
  );
  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeStudentModalFilter
  );

  const handleChangeGeneralClassFilter = React.useMemo(
    () =>
      memoize(() => (idValue: number, value: Model) => {
        const newFilter = {
          ...studentFilter,
          className: {
            equal: value?.name,
          },
          generalClass: value,
        };
        handleChangeStudentModalFilter(newFilter);
      }),
    [handleChangeStudentModalFilter, studentFilter]
  );

  const handleChangeCohortFilter = React.useMemo(
    () =>
      memoize(() => (idValue: number, value: Model) => {
        const newFilter = {
          ...studentFilter,
          cohortId: {
            equal: idValue,
          },
          cohort: value,
        };
        handleChangeStudentModalFilter(newFilter);
      }),
    [handleChangeStudentModalFilter, studentFilter]
  );

  const handleChangeSelectTypeFilter = React.useMemo(
    () =>
      memoize(
        (config: {
            fieldName: string;
            fieldType: string;
            classFilter: new (partial?: any) => IdFilter | GuidFilter;
          }) =>
          (idValue: number, value: Model) => {
            const { fieldName, fieldType, classFilter: ClassFilter } = config;
            const newFilter =
              idValue === 1
                ? {
                    ...studentFilter,
                    [`${fieldName}Value`]: value,
                    [`${fieldName}Id`]: Object.assign(new ClassFilter(), {
                      [fieldType]: idValue,
                    }),
                    id: {
                      in: checkedKeys,
                    },
                    skip: 0,
                  }
                : idValue === 2
                ? {
                    ...studentFilter,
                    [`${fieldName}Value`]: value,
                    [`${fieldName}Id`]: Object.assign(new ClassFilter(), {
                      [fieldType]: idValue,
                    }),
                    id: {
                      notIn: checkedKeys?.length > 0 ? checkedKeys : undefined,
                    },
                    skip: 0,
                  }
                : {
                    ...studentFilter,
                    [`${fieldName}Value`]: value,
                    [`${fieldName}Id`]: Object.assign(new ClassFilter(), {
                      [fieldType]: idValue,
                    }),
                    id: new IdFilter(),
                    skip: 0,
                  };
            handleChangeStudentModalFilter(newFilter);
          }
      ),
    [checkedKeys, handleChangeStudentModalFilter, studentFilter]
  );

  const handleChangeInputFilter = React.useCallback(
    (value: any) => {
      const newFilter = {
        ...studentFilter,
        search: value,
        skip: 0,
      };
      handleChangeStudentAllFilter(newFilter);
      handleGetListMapping(newFilter);
    },
    [handleChangeStudentAllFilter, handleGetListMapping, studentFilter]
  );
  const handleChangeTagFilter = React.useCallback(
    (valueFilter: any) => {
      if (!valueFilter?.selectTypeId?.equal) {
        valueFilter.id = new IdFilter();
      }
      const newFilter = {
        ...valueFilter,
      };
      handleChangeStudentAllFilter(newFilter);
      handleGetListMapping(newFilter);
    },
    [handleChangeStudentAllFilter, handleGetListMapping]
  );
  return {
    open: visible,
    listMapping,
    countMapping,
    studentFilter,
    spinning,
    rowSelection,
    handleOpenMapping,
    handleCloseMapping: handleCloseMappingModal,
    handleSaveMapping,
    handleCancelMapping,
    handleChangeTagFilter,
    handleChangeStudentAllFilter,
    handleChangeGeneralClassFilter,
    handleChangeCohortFilter,
    handleChangeInputSearch,
    handleTableChange,
    handlePagination,
    handleChangeInputFilter,
    handleChangeSelectTypeFilter,
    handleChangerRowSelection,
  };
}
