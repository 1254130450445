/* begin general import */
import { Checkbox, Col, Row, Spin, Switch, Tabs } from "antd";
import nameof from "ts-nameof.macro";
import { utilService } from "services/common-services/util-service";
import FormItem from "react3l-ui-library/build/components/FormItem";
import {
  Button,
  DatePicker,
  InputNumber,
  Select,
  StandardTable,
} from "react3l-ui-library";
import InputText from "react3l-ui-library/build/components/Input/InputText";
import {
  Add16,
  AddAlt16,
  Close16,
  Save16,
  TrashCan16,
} from "@carbon/icons-react";
import _ from "lodash";
import "./CourseSectionDetail.scss";
import { useTranslation } from "react-i18next";
import { STANDARD_DATE_FORMAT_INVERSE, STATUS_ENUM } from "config/consts";
import { useCourseSectionStudentTable } from "./CourseSectionDetailHook/CourseSectionStudentMapping/CourseSectionStudentTableHook";
import { useCourseSectionStudentModal } from "./CourseSectionDetailHook/CourseSectionStudentMapping/CourseSectionStudentModalHook";
import { CourseSectionStudentModal } from "./CourseSectionDetailHook/CourseSectionStudentMapping/CourseSectionStudentModal";
import { courseSectionRepository } from "repositories/course-section-repository";
import { SubjectFilter } from "models/Subject";
import { webService } from "services/common-services/web-service";
import { detailService } from "services/page-services/detail-service";
import { CourseSection } from "models/CourseSection";
import { fieldService } from "services/page-services/field-service";
import { COURSE_SECTION_ROUTE } from "config/route-consts";
import { AppUser, AppUserFilter } from "models/AppUser";
import { SemesterFilter } from "models/Semester";
import MultipleSelect from "components/MultipleSelect";
import React from "react";
import useCourseSectionShift from "./CourseSectionDetailHook/CourseSectionShift/CourseSectionShiftTableHook";
import { Moment } from "moment";

/* end individual import */

function CourseSectionDetail() {
  const [translate] = useTranslation();

  // use hook
  const { model, dispatch } =
    detailService.useModel<CourseSection>(CourseSection);

  const { isDetail } = detailService.useGetIsDetail<CourseSection>(
    courseSectionRepository.get,
    dispatch
  );

  const {
    handleChangeDateField,
    handleChangeAllField,
    handleChangeSingleField,
    handleChangeSelectField,
    handleChangeListField,
  } = fieldService.useField(model, dispatch);

  const { handleSaveModel, handleGoMaster } =
    detailService.useActionsDetail<CourseSection>(
      model,
      courseSectionRepository.save,
      handleChangeAllField,
      COURSE_SECTION_ROUTE
    );

  const { listMapping: listAppUserMapping } = webService.useConvertMappingData(
    model?.courseSectionAppUserMappings,
    "appUser"
  );

  const [tabKey, setTabKey] = React.useState<string>("1");

  const handleChangeTab = React.useCallback((activetabKey: string) => {
    setTabKey(activetabKey);
  }, []);

  const {
    list: studentList,
    selectedRowKeys,
    rowSelection,
    studentCourseSectionMappingColumns,
    handleDeleteItems,
  } = useCourseSectionStudentTable(model, handleChangeAllField);

  const {
    open: visibleStudentModal,
    listMapping: listStudent,
    countMapping: countStudent,
    studentFilter,
    spinning,
    handleOpenMapping: handleOpenStudentModal,
    handleCloseMapping: handleCloseStudentModal,
    handleSaveMapping: handleSaveStudentModal,
    rowSelection: rowSelectionStudentModal,
    handleTableChange: handleContentModalTableChange,
    handlePagination: handleChangePaginationStudentModal,
    handleChangeInputFilter,
    handleChangeGeneralClassFilter,
    handleChangeCohortFilter,
    handleChangerRowSelection,
  } = useCourseSectionStudentModal(model, handleChangeAllField);

  const {
    courseSectionShiftList,
    handleAddCourseSectionShift,
    courseSectionShiftColumns,
  } = useCourseSectionShift(model, dispatch);

  const isCourseSectionStudentMappingsTabPaneError = React.useMemo(() => {
    const checkIfGeneralErrors = model?.errors?.courseSectionStudentMappings;
    const courseSectionStudentMappings = model?.courseSectionStudentMappings
      ? _.cloneDeep(model?.courseSectionStudentMappings)
      : [];
    const checkIfDetailErrors = courseSectionStudentMappings?.some(
      (item) => item?.generalErrors?.length > 0
    );
    return checkIfGeneralErrors || checkIfDetailErrors;
  }, [
    model?.courseSectionStudentMappings,
    model?.errors?.courseSectionStudentMappings,
  ]);

  const isCourseSectionShiftsTabPaneError = React.useMemo(() => {
    const checkIfGeneralErrors = model?.errors?.courseSectionShifts;
    const courseSectionShifts = model?.courseSectionShifts
      ? _.cloneDeep(model?.courseSectionShifts)
      : [];
    const checkIfDetailErrors = courseSectionShifts?.some(
      (item) => item?.generalErrors?.length > 0
    );
    return checkIfGeneralErrors || checkIfDetailErrors;
  }, [model?.courseSectionShifts, model?.errors?.courseSectionShifts]);
  return (
    <Spin spinning={false}>
      <div className="page-content page-content-dxg" id="page-content-dxg-id">
        <div className="info-component m-l--sm m-r--xxl mb-3">
          <span>{translate("courseSections.master.header")}</span>
          <span> &gt; </span>
          <span className="info-primary">
            {isDetail
              ? translate("general.detail.title")
              : translate("general.actions.create")}
          </span>
        </div>
        <div className="page page-detail">
          <div className="d-flex">
            <h5 className="font-bold page-title m-l--sm m-t--sm">
              {isDetail
                ? `${model.code} - ${model.subject?.name}`
                : translate("courseSections.detail.titleCreate")}
            </h5>

            <div className="m-t--sm m-l--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.status)
                )}
                message={model.errors?.status}
              >
                <Switch
                  checked={model.statusId === 1 ? true : false}
                  onChange={(checked: boolean) => {
                    handleChangeSingleField({
                      fieldName: "statusId",
                    })(checked ? 1 : 0);
                    handleChangeSingleField({
                      fieldName: "status",
                    })(checked ? STATUS_ENUM[1] : STATUS_ENUM[0]);
                  }}
                  className="switch_status"
                />
              </FormItem>
            </div>
          </div>
          <div className="page-detail__content">
            <div className="page-detail__title p-b--lg">
              {translate("courseSections.detail.generalTitle")}
            </div>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {/* Mã ngành học*/}
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.code)
                  )}
                  message={model.errors?.code}
                >
                  <InputText
                    label={translate("courseSections.code")}
                    type={0}
                    value={model.code}
                    placeHolder={translate("courseSections.placeholder.code")}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.code),
                    })}
                    isRequired
                    showCount
                    maxLength={20}
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.subject)
                  )}
                  message={model.errors?.subject}
                >
                  <Select
                    label={translate("courseSections.subject")}
                    type={0}
                    classFilter={SubjectFilter}
                    placeHolder={translate(
                      "courseSections.placeholder.subject"
                    )}
                    getList={courseSectionRepository.singleListSubject}
                    onChange={handleChangeSelectField({
                      fieldName: nameof(model.subject),
                    })}
                    value={model.subject}
                    isRequired
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.courseSectionAppUserMappings)
                  )}
                  message={model.errors?.courseSectionAppUserMappings}
                >
                  <MultipleSelect
                    isUsingSearch
                    label={translate(
                      "courseSections.courseSectionAppUserMappings"
                    )}
                    type={0}
                    placeHolder={translate(
                      "courseSections.placeholder.courseSectionAppUserMappings"
                    )}
                    onChange={(values: AppUser[]) => {
                      const selectedList = values.map((item: AppUser) => {
                        return {
                          appUser: item,
                          appUserId: item?.id,
                        };
                      });
                      handleChangeListField({
                        fieldName: nameof(model.courseSectionAppUserMappings),
                      })(selectedList);
                    }}
                    getList={courseSectionRepository.singleListAppUser}
                    values={listAppUserMapping}
                    classFilter={AppUserFilter}
                    maxLengthItem={50}
                    render={(item: AppUser) => {
                      return item?.displayName;
                    }}
                    searchType={""}
                    searchProperty="search"
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.semester)
                  )}
                  message={model.errors?.semester}
                >
                  <Select
                    label={translate("courseSections.semester")}
                    type={0}
                    classFilter={SemesterFilter}
                    placeHolder={translate(
                      "courseSections.placeholder.semester"
                    )}
                    getList={courseSectionRepository.singleListSemester}
                    onChange={(id, T) => {
                      handleChangeSelectField({
                        fieldName: nameof(model.semester),
                      })(id, T);
                      handleChangeDateField({
                        fieldName: nameof(model.startedAt),
                      })(T?.startedAt?.startOf("day"));
                      handleChangeDateField({
                        fieldName: nameof(model.endedAt),
                      })(T?.endedAt?.endOf("day"));
                    }}
                    value={model.semester}
                    isRequired
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.numberOfCreditHours)
                  )}
                  message={model.errors?.numberOfCreditHours}
                >
                  <InputNumber
                    label={translate("courseSections.numberOfCreditHours")}
                    type={0}
                    value={model.numberOfCreditHours}
                    placeHolder={translate(
                      "courseSections.placeholder.numberOfCreditHours"
                    )}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.numberOfCreditHours),
                    })}
                    isRequired
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.startedAt)
                  )}
                  message={model.errors?.startedAt}
                >
                  <DatePicker
                    label={translate("courseSections.startedAt")}
                    value={model.startedAt}
                    type={0}
                    placeholder={translate(
                      "courseSections.placeholder.startedAt"
                    )}
                    onChange={(date: Moment) =>
                      handleChangeDateField({
                        fieldName: nameof(model.startedAt),
                      })(date?.startOf("day"))
                    }
                    isRequired
                    disabled={model?.isStartSameSemester}
                    dateFormat={[STANDARD_DATE_FORMAT_INVERSE]}
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.endedAt)
                  )}
                  message={model.errors?.endedAt}
                >
                  <DatePicker
                    label={translate("courseSections.endedAt")}
                    value={model.endedAt}
                    type={0}
                    placeholder={translate(
                      "courseSections.placeholder.endedAt"
                    )}
                    onChange={(date: Moment) =>
                      handleChangeDateField({
                        fieldName: nameof(model.endedAt),
                      })(date?.endOf("day"))
                    }
                    isRequired
                    disabled={model?.isStartSameSemester}
                    dateFormat={[STANDARD_DATE_FORMAT_INVERSE]}
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <div className="align-items-center h-100 d-flex">
                  <Checkbox
                    className="checkbox"
                    checked={model?.isStartSameSemester}
                    onClick={(e: any) => {
                      handleChangeSingleField({
                        fieldName: nameof(model.isStartSameSemester),
                      })(e?.target?.checked);
                      if (e?.target?.checked === true) {
                        handleChangeDateField({
                          fieldName: nameof(model.startedAt),
                        })(model?.semester?.startedAt?.startOf("day"));
                        handleChangeDateField({
                          fieldName: nameof(model.endedAt),
                        })(model?.semester?.endedAt?.endOf("day"));
                      }
                    }}
                    disabled={!model?.semesterId ? true : false}
                  />
                  <div className="label-status p-l--xxs">
                    {translate("courseSections.isStartSameSemester")}
                  </div>
                </div>
              </Col>
            </Row>

            <div className="p-t--lg p-b--space-ultra-wire">
              <Tabs
                defaultActiveKey="1"
                color="red"
                items={[
                  {
                    key: "1",
                    label: (
                      <div
                        className={
                          isCourseSectionStudentMappingsTabPaneError
                            ? "error-tab"
                            : ""
                        }
                      >
                        {translate(
                          "courseSections.courseSectionStudentMappings.title"
                        )}
                      </div>
                    ),
                    children: (
                      <>
                        <div className="d-flex justify-content-end">
                          <div className="d-flex align-items-center justify-content-right">
                            <Button
                              type="outline-primary"
                              className="btn--lg btn-orange-export ml-3"
                              icon={<Add16 />}
                              onClick={handleOpenStudentModal}
                            >
                              {translate("courseSections.addStudent")}
                            </Button>
                            <Button
                              type="danger"
                              className="btn--lg btn-delete-items btn-red ml-3 mr-4"
                              icon={<TrashCan16 />}
                              onClick={handleDeleteItems}
                              disabled={
                                selectedRowKeys?.length === 0 ||
                                model?.courseSectionStudentMappings?.length ===
                                  0
                              }
                            >
                              {translate("courseSections.deleteStudent")}
                            </Button>
                          </div>
                        </div>

                        <StandardTable
                          rowKey={"studentId"}
                          columns={studentCourseSectionMappingColumns}
                          dataSource={studentList}
                          rowSelection={rowSelection}
                          isDragable={true}
                          tableSize={"md"}
                          scroll={{ y: 500 }}
                          bordered
                        />

                        <div className="error-notification-contents_block">
                          {model?.errors?.courseSectionStudentMappings}
                        </div>
                      </>
                    ),
                  },
                  {
                    key: "2",
                    label: (
                      <div
                        className={
                          isCourseSectionShiftsTabPaneError ? "error-tab" : ""
                        }
                      >
                        {translate("courseSections.courseSectionShifts.title")}
                      </div>
                    ),
                    children: (
                      <div>
                        <StandardTable
                          rowKey="key"
                          columns={courseSectionShiftColumns}
                          dataSource={courseSectionShiftList}
                          tableSize={"md"}
                          scroll={{ y: 500 }}
                          bordered
                        />
                        <div className="error-notification-contents_block">
                          {model?.errors?.courseSectionShifts}
                        </div>

                        <div className="m-t--sm">
                          <span
                            onClick={handleAddCourseSectionShift}
                            className={"add-content-icon"}
                          >
                            <AddAlt16 />
                            <span className="m-l--xxxs p-b--space-ultra-wire">
                              {translate(
                                "courseSections.courseSectionShifts.add"
                              )}
                            </span>
                          </span>
                        </div>
                      </div>
                    ),
                  },
                ]}
                onChange={handleChangeTab}
                activeKey={tabKey}
              />
            </div>
          </div>
        </div>
        <footer className="app-footer">
          <div className="app-footer__full d-flex justify-content-end align-items-center">
            <div className="app-footer__actions d-flex justify-content-end">
              <Button
                type="secondary"
                className="btn--lg btn-red"
                icon={<Close16 />}
                onClick={handleGoMaster}
              >
                {translate("general.actions.close")}
              </Button>

              <Button
                type="primary"
                className="btn--lg btn-blue ml-3"
                icon={<Save16 />}
                onClick={handleSaveModel}
              >
                {translate("general.actions.save")}
              </Button>
            </div>
          </div>
        </footer>
      </div>
      <CourseSectionStudentModal
        handleClose={handleCloseStudentModal}
        handleSave={handleSaveStudentModal}
        countStudent={countStudent}
        listStudent={listStudent}
        visible={visibleStudentModal}
        handleChangeInputFilter={handleChangeInputFilter}
        handleChangeGeneralClassFilter={handleChangeGeneralClassFilter}
        handleChangeCohortFilter={handleChangeCohortFilter}
        studentFilter={studentFilter}
        handlePagination={handleChangePaginationStudentModal}
        rowSelection={rowSelectionStudentModal}
        loadList={spinning}
        handleTableChange={handleContentModalTableChange}
        handleChangerRowSelection={handleChangerRowSelection}
      />
    </Spin>
  );
}

export default CourseSectionDetail;
