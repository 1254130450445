import { COHORT_MASTER_ROUTE } from "config/route-consts";
import { ProtectedRoute } from "pages/Authentication/ProtectedRoute";
import { Switch } from "react-router-dom";
import CohortMaster from "./CohortMaster/CohortMaster";

function CohortView() {
  return (
    <Switch>
      <ProtectedRoute
        path={COHORT_MASTER_ROUTE}
        key={COHORT_MASTER_ROUTE}
        component={CohortMaster}
        auth={true}
      />
    </Switch>
  );
}

export { CohortMaster };
export default CohortView;
