import { Repository } from "react3l-common";
import { kebabCase } from "lodash";
import { httpConfig } from "config/http";
import { BASE_API_URL } from "config/consts";
import { Observable } from "rxjs";
import { AxiosResponse } from "axios";
import nameof from "ts-nameof.macro";
import { API_COHORT_PREFIX } from "config/api-consts";
import { Status, StatusFilter } from "models/Status";
import { Subject, SubjectFilter } from "models/Subject";
import { Organization, OrganizationFilter } from "models/Organization";
import { Cohort, CohortFilter } from "models/Cohort";

export type KeyType = string | number;

export class CohortRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = new URL(API_COHORT_PREFIX, BASE_API_URL).href;
  }

  public count = (cohortFilter?: CohortFilter): Observable<number> => {
    return this.http
      .post<number>(kebabCase(nameof(this.count)), cohortFilter)
      .pipe(Repository.responseDataMapper<number>());
  };

  public list = (cohortFilter?: CohortFilter): Observable<Cohort[]> => {
    return this.http
      .post<Cohort[]>(kebabCase(nameof(this.list)), cohortFilter)
      .pipe(Repository.responseMapToList<Cohort>(Cohort));
  };

  public countSubject = (subjectFilter?: SubjectFilter): Observable<number> => {
    return this.http
      .post<number>(kebabCase(nameof(this.countSubject)), subjectFilter)
      .pipe(Repository.responseDataMapper<number>());
  };

  public listSubject = (
    subjectFilter?: SubjectFilter
  ): Observable<Cohort[]> => {
    return this.http
      .post<Cohort[]>(kebabCase(nameof(this.listSubject)), subjectFilter)
      .pipe(Repository.responseMapToList<Cohort>(Cohort));
  };

  public get = (id: number | string): Observable<Cohort> => {
    return this.http
      .post<Cohort>(kebabCase(nameof(this.get)), { id })
      .pipe(Repository.responseMapToModel<Cohort>(Cohort));
  };

  public create = (cohort: Cohort): Observable<Cohort> => {
    return this.http
      .post<Cohort>(kebabCase(nameof(this.create)), cohort)
      .pipe(Repository.responseMapToModel<Cohort>(Cohort));
  };

  public update = (cohort: Cohort): Observable<Cohort> => {
    return this.http
      .post<Cohort>(kebabCase(nameof(this.update)), cohort)
      .pipe(Repository.responseMapToModel<Cohort>(Cohort));
  };

  public delete = (cohort: Cohort): Observable<Cohort> => {
    return this.http
      .post<Cohort>(kebabCase(nameof(this.delete)), cohort)
      .pipe(Repository.responseMapToModel<Cohort>(Cohort));
  };

  public bulkDelete = (idList: KeyType[]): Observable<void> => {
    return this.http
      .post(kebabCase(nameof(this.bulkDelete)), idList)
      .pipe(Repository.responseDataMapper());
  };

  public save = (cohort: Cohort): Observable<Cohort> => {
    return cohort.id ? this.update(cohort) : this.create(cohort);
  };

  public singleListStatus = (): Observable<Status[]> => {
    return this.http
      .post<Status[]>(
        kebabCase(nameof(this.singleListStatus)),
        new StatusFilter()
      )
      .pipe(Repository.responseMapToList<Status>(Status));
  };

  public filterListStatus = (): Observable<Status[]> => {
    return this.http
      .post<Status[]>(
        kebabCase(nameof(this.filterListStatus)),
        new StatusFilter()
      )
      .pipe(Repository.responseMapToList<Status>(Status));
  };

  public singleListSubject = (
    subjectFilter: SubjectFilter
  ): Observable<Subject[]> => {
    return this.http
      .post<Subject[]>(kebabCase(nameof(this.singleListSubject)), subjectFilter)
      .pipe(Repository.responseMapToList<Subject>(Subject));
  };
  public filterListSubject = (
    subjectFilter: SubjectFilter
  ): Observable<Subject[]> => {
    return this.http
      .post<Subject[]>(kebabCase(nameof(this.filterListSubject)), subjectFilter)
      .pipe(Repository.responseMapToList<Subject>(Subject));
  };

  public singleListOrganization = (
    organizationFilter: OrganizationFilter
  ): Observable<Organization[]> => {
    return this.http
      .post<Organization[]>(
        kebabCase(nameof(this.singleListOrganization)),
        organizationFilter
      )
      .pipe(Repository.responseMapToList<Organization>(Organization));
  };
  public filterListOrganization = (
    organizationFilter: OrganizationFilter
  ): Observable<Organization[]> => {
    return this.http
      .post<Organization[]>(
        kebabCase(nameof(this.filterListOrganization)),
        organizationFilter
      )
      .pipe(Repository.responseMapToList<Organization>(Organization));
  };

  public import = (
    file: File,
    name: string = nameof(file)
  ): Observable<void> => {
    const formData: FormData = new FormData();
    formData.append(name, file as Blob);
    return this.http
      .post<void>(kebabCase(nameof(this.import)), formData)
      .pipe(Repository.responseDataMapper<any>());
  };

  public importMapping = (
    file: File,
    name: string = nameof(file)
  ): Observable<void> => {
    const formData: FormData = new FormData();
    formData.append(name, file as Blob);
    return this.http
      .post<void>(kebabCase(nameof(this.importMapping)), formData)
      .pipe(Repository.responseDataMapper<any>());
  };

  public export = (filter: any): Observable<AxiosResponse<any>> => {
    return this.http.post("export", filter, {
      responseType: "arraybuffer",
    });
  };

  public exportTemplate = (): Observable<AxiosResponse<any>> => {
    return this.http.post(
      "export-template",
      {},
      {
        responseType: "arraybuffer",
      }
    );
  };

  public exportTemplateMapping = (): Observable<AxiosResponse<any>> => {
    return this.http.post(
      "export-template-mapping",
      {},
      {
        responseType: "arraybuffer",
      }
    );
  };
}

export const cohortRepository = new CohortRepository();
