/* begin general import */
import { Col, Row } from "antd";
import { ASSETS_SVG, STANDARD_DATE_FORMAT_INVERSE } from "config/consts";
import { Cohort } from "models/Cohort";
import { Moment } from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "react3l-ui-library";
import Drawer, {
  DrawerProps,
} from "react3l-ui-library/build/components/Drawer/Drawer";
import FormItem from "react3l-ui-library/build/components/FormItem";
import InputText from "react3l-ui-library/build/components/Input/InputText";
import { utilService } from "services/common-services/util-service";
import { ModelAction } from "services/page-services/detail-service";
import { ConfigField } from "services/page-services/field-service";
import nameof from "ts-nameof.macro";

/* end individual import */

interface CohortDetailDrawerProps extends DrawerProps {
  model: Cohort;
  handleChangeSingleField: (config: {
    fieldName: string;
  }) => (value: any) => void;
  handleChangeDateField: (
    config: ConfigField
  ) => (date: Moment | [Moment, Moment]) => void;
  dispatch?: React.Dispatch<ModelAction<Cohort>>;
  loading?: boolean;
}

function CohortDetailDrawer(props: CohortDetailDrawerProps) {
  const [translate] = useTranslation();

  const {
    model,
    handleChangeSingleField,
    handleChangeDateField,
    loading,
    visible,
    handleSave,
    handleCancel,
  } = props;

  return (
    <Drawer
      {...props}
      visible={visible}
      handleSave={handleSave}
      handleCancel={handleCancel}
      handleClose={handleCancel}
      visibleFooter={true}
      title={
        model?.id
          ? translate("general.detail.title")
          : translate("general.actions.create")
      }
      titleButtonCancel={translate("general.actions.cancel")}
      titleButtonApply={translate("general.actions.save")}
    >
      {loading ? (
        <div className="loading-block">
          <img src={ASSETS_SVG + "/spinner.svg"} alt="Loading..." />
        </div>
      ) : (
        <div className="page page__detail">
          <div className="w-100 page__detail-tabs">
            <Row className="d-flex">
              <Col lg={24}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col lg={24} className="m-b--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.code)
                      )}
                      message={model.errors?.code}
                    >
                      <InputText
                        label={translate("cohorts.code")}
                        isRequired
                        type={0}
                        value={model.code}
                        placeHolder={translate("cohorts.placeholder.code")}
                        className={"tio-account_square_outlined"}
                        onChange={handleChangeSingleField({
                          fieldName: nameof(model.code),
                        })}
                        showCount
                        maxLength={20}
                      />
                    </FormItem>
                  </Col>

                  <Col lg={24} className="m-b--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.name)
                      )}
                      message={model.errors?.name}
                    >
                      <InputText
                        label={translate("cohorts.name")}
                        isRequired={true}
                        type={0}
                        value={model.name}
                        placeHolder={translate("cohorts.placeholder.name")}
                        className={"tio-account_square_outlined"}
                        onChange={handleChangeSingleField({
                          fieldName: nameof(model.name),
                        })}
                        maxLength={255}
                        showCount
                      />
                    </FormItem>
                  </Col>

                  <Col lg={24} className="m-b--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.startedAt)
                      )}
                      message={model.errors?.startedAt}
                    >
                      <DatePicker
                        label={translate("cohorts.startedAt")}
                        value={model.startedAt}
                        type={0}
                        placeholder={translate("cohorts.placeholder.startedAt")}
                        onChange={(date: Moment) =>
                          handleChangeDateField({
                            fieldName: nameof(model.startedAt),
                          })(date?.startOf("day").millisecond(0o1))
                        }
                        isRequired
                        dateFormat={[STANDARD_DATE_FORMAT_INVERSE]}
                      />
                    </FormItem>
                  </Col>

                  <Col lg={24} className="m-b--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.endedAt)
                      )}
                      message={model.errors?.endedAt}
                    >
                      <DatePicker
                        label={translate("cohorts.endedAt")}
                        value={model.endedAt}
                        type={0}
                        placeholder={translate("cohorts.placeholder.endedAt")}
                        onChange={(date: Moment) =>
                          handleChangeDateField({
                            fieldName: nameof(model.endedAt),
                          })(date?.endOf("day").millisecond(998))
                        }
                        isRequired
                        dateFormat={[STANDARD_DATE_FORMAT_INVERSE]}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Drawer>
  );
}

export default CohortDetailDrawer;
