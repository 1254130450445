import { Status } from "models/Status";
import { ObjectField } from "react3l-decorators";
import { Student } from "./Student";
import nameof from "ts-nameof.macro";
import { Major } from "models/Major";
import { Gender } from "models/Gender";
import { Cohort } from "models/Cohort";

ObjectField(Status)(Student.prototype, nameof(Student.prototype.status));
ObjectField(Major)(Student.prototype, nameof(Student.prototype.major));
ObjectField(Gender)(Student.prototype, nameof(Student.prototype.gender));
ObjectField(Cohort)(Student.prototype, nameof(Student.prototype.cohort));

export * from "./Student";
export * from "./StudentFilter";
