/* begin general import */
import { Col, Row, Spin, Switch } from "antd";
import nameof from "ts-nameof.macro";
import { utilService } from "services/common-services/util-service";
import FormItem from "react3l-ui-library/build/components/FormItem";
import { Button, DatePicker, Select } from "react3l-ui-library";
import InputText from "react3l-ui-library/build/components/Input/InputText";
import { Close16, Save16 } from "@carbon/icons-react";
import _ from "lodash";
import "./StudentDetail.scss";
import { useTranslation } from "react-i18next";
import { STANDARD_DATE_FORMAT_INVERSE, STATUS_ENUM } from "config/consts";
import { studentRepository } from "repositories/student-repository";
import { GenderFilter } from "models/Gender";
import useStudentDetail from "./StudentDetailHook";
import { CohortFilter } from "models/Cohort";

/* end individual import */

function StudentDetail() {
  const [translate] = useTranslation();

  const {
    model,
    isDetail,
    handleChangeSingleField,
    handleChangeSelectField,
    handleChangeDateField,
    handleGoMaster,
    handleSaveModel,
  } = useStudentDetail();

  return (
    <Spin spinning={false}>
      <div className="page-content page-content-dxg" id="page-content-dxg-id">
        <div className="info-component m-l--sm m-r--xxl mb-3">
          <span>{translate("students.master.header")}</span>
          <span> &gt; </span>
          <span className="info-primary">
            {!isDetail
              ? translate("general.actions.create")
              : translate("general.detail.title")}
          </span>
        </div>
        <div className="page page-detail">
          <div className="d-flex">
            <h5 className="font-bold page-title m-l--sm m-t--sm">
              {isDetail
                ? translate("students.detail.titleDetail")
                : translate("students.detail.titleCreate")}
            </h5>
            {/* Trạng thái */}
            <div className="m-t--sm m-l--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.status)
                )}
                message={model.errors?.status}
              >
                <Switch
                  checked={model.statusId === 1 ? true : false}
                  onChange={(checked: boolean) => {
                    handleChangeSingleField({
                      fieldName: "statusId",
                    })(checked ? 1 : 0);
                    handleChangeSingleField({
                      fieldName: "status",
                    })(checked ? STATUS_ENUM[1] : STATUS_ENUM[0]);
                  }}
                  className="switch_status"
                />
              </FormItem>
            </div>
          </div>

          <div className="page-detail__content">
            <div className="page-detail__title p-b--lg">
              {translate("students.detail.generalTitle")}
            </div>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {/* Mã ngành học*/}
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.code)
                  )}
                  message={model.errors?.code}
                >
                  <InputText
                    label={translate("students.code")}
                    isRequired
                    type={0}
                    value={model.code}
                    placeHolder={translate("students.placeholder.code")}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.code),
                    })}
                    showCount
                    maxLength={20}
                  />
                </FormItem>
              </Col>
              {/* Tên ngành học */}
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.name)
                  )}
                  message={model.errors?.name}
                >
                  <InputText
                    label={translate("students.name")}
                    isRequired={true}
                    type={0}
                    value={model.name}
                    placeHolder={translate("students.placeholder.name")}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.name),
                    })}
                    maxLength={255}
                    showCount
                  />
                </FormItem>
              </Col>
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.className)
                  )}
                  message={model.errors?.className}
                >
                  <InputText
                    label={translate("students.className")}
                    isRequired={true}
                    type={0}
                    value={model.className}
                    placeHolder={translate("students.placeholder.className")}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.className),
                    })}
                    maxLength={255}
                    showCount
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.cohort)
                  )}
                  message={model.errors?.cohort}
                >
                  <Select
                    label={translate("students.cohort")}
                    type={0}
                    classFilter={CohortFilter}
                    placeHolder={translate("students.placeholder.cohort")}
                    getList={studentRepository.singleListCohort}
                    onChange={handleChangeSelectField({
                      fieldName: nameof(model.cohort),
                    })}
                    value={model.cohort}
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.major)
                  )}
                  message={model.errors?.major}
                >
                  <Select
                    label={translate("students.major")}
                    type={0}
                    classFilter={GenderFilter}
                    placeHolder={translate("students.placeholder.major")}
                    getList={studentRepository.singleListMajor}
                    onChange={handleChangeSelectField({
                      fieldName: nameof(model.major),
                    })}
                    value={model.major}
                    isRequired
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.gender)
                  )}
                  message={model.errors?.gender}
                >
                  <Select
                    label={translate("students.gender")}
                    type={0}
                    classFilter={GenderFilter}
                    placeHolder={translate("students.placeholder.gender")}
                    getList={studentRepository.singleListGender}
                    onChange={handleChangeSelectField({
                      fieldName: nameof(model.gender),
                    })}
                    value={model.gender}
                  />
                </FormItem>
              </Col>

              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.doB)
                  )}
                  message={model.errors?.doB}
                >
                  <DatePicker
                    label={translate("students.doB")}
                    value={model.doB}
                    type={0}
                    placeholder={translate("students.placeholder.doB")}
                    onChange={handleChangeDateField({
                      fieldName: nameof(model.doB),
                    })}
                    dateFormat={[STANDARD_DATE_FORMAT_INVERSE]}
                  />
                </FormItem>
              </Col>
            </Row>
          </div>
        </div>
        <footer className="app-footer">
          <div className="app-footer__full d-flex justify-content-end align-items-center">
            <div className="app-footer__actions d-flex justify-content-end">
              <Button
                type="secondary"
                className="btn--lg btn-red"
                icon={<Close16 />}
                onClick={handleGoMaster}
              >
                {translate("general.actions.close")}
              </Button>

              <Button
                type="primary"
                className="btn--lg btn-blue ml-3"
                icon={<Save16 />}
                onClick={handleSaveModel}
              >
                {translate("general.actions.save")}
              </Button>
            </div>
          </div>
        </footer>
      </div>
    </Spin>
  );
}

export default StudentDetail;
